import {
    Center,
    ChakraProvider,
    ColorModeScript,
    Flex,
    Grid,
    GridItem,
    HStack, InputLeftAddon,
    Link,
    SimpleGrid, Stack,
    useToast,
} from '@chakra-ui/react'
import React, {useRef, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardBody, CardFooter,
    Divider,
    Editable, EditableInput,
    EditablePreview,
    Heading, Input,
    InputGroup,
    Text,
    Textarea, VStack,
} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import {useInViewport} from "react-in-viewport";

function Contact(){

    const title_style_1 = { base: "21px", sm: "30px", md: "3vw", lg: "32px", "2xl":"40px"};
    const title_style_2 = { base: "14px", sm: "15px", md: "3vw", lg: "30px", xl: "2vh","2xl":"2vh"};
    const titleAnimation = keyframes `
        from {transform: translateX(5vw) ; opacity :0.2 ;}
        to {transform: translateX(0vw); opacity: 1;}
    `
    const title_animation = `${titleAnimation} 1s ease-in-out `

    const formAnimation = keyframes `
        from {transform: translateX(-5vw) ; opacity :0.2 ;}
        to {transform: translateX(0vw); opacity: 1;}
    `
    const form_animation = `${formAnimation} 1s ease-in-out `

    require('intersection-observer');
    const myref = useRef();
    const {inViewport, enterCount,
        leaveCount,} = useInViewport(
        myref,
        {disconnectOnLeave: true},
    );

    function sendEmail(){
        const first_name = document.getElementById("first_name").value
        const last_name = document.getElementById("last_name").value
        const email = document.getElementById("email").value
        const phone = document.getElementById("phone").value
        const quote = document.getElementById("quote").value

        if (first_name.trim().length === 0 || last_name.trim().length === 0
            ||email.trim().length === 0 ||phone.trim().length === 0 ||
            quote.trim().length === 0){
            alert("Please fill every detail.")
            console.log("nononononon")
        }else{
            window.Email.send({
                SecureToken : "3319952a-5cca-4a22-89ce-8a1e0d6e92c7",
                To : 'mjcarpentrysales@outlook.com',
                From : 'mjcarpentrysales@outlook.com',
                Subject : "New Quote!",
                Body : ` 
                1. Name<br />
                ${first_name + " " + last_name}<br /><br />
                2. Email<br />
                ${email}<br /><br />
                3. Phone Number<br />
                ${phone}<br /><br />
                4. Quote<br />
                ${quote}<br /><br />
                   `,
            }).then(message=>{
                if (message === "OK")
                {
                    alert("You have successfully send an email to us!")
                    console.log("ok")

                }else{
                    console.log("error")
                    alert("Internal error")
                }}
            );
        }



    }


    return(
        <>
            <VStack mt = "2vh" ref = {myref} spacing = "10px" minH = "50vh" w = {{base: "100vw" , md:"80vw"}} marginBottom={"10vh"} bg = "white" paddingTop = "100px" paddingBottom = "100px">
                <Heading paddingBottom = "70px" fontSize={title_style_1}>
                    Book a free Measure & Quote</Heading>

                <VStack mb={"2vh"} spacing = "20px" w = {{base:"70vw", md: "40vw"}} animation = {enterCount===1?form_animation:""} >
                    <Text align = "start" fontSize={title_style_2}>MJ carpentry and joinery offers competitive pricing but high quality product, a
                        free measure and quote, professional supply, installation and custom made carpentry and renovation
                        services. <strong>Please input everything required to send a quote successfully</strong>
                    </Text>
                    <Stack direction={{base:"column", md: "row"}} align = "start" w={ "100%"} justifyContent={"space-between"}>
                        <VStack align = "start" w = {{base:"90%", md: "49%"}}>
                            <Text fontSize={"lg"}>First Name<span  style={{ color: 'red' }}>*</span></Text>
                            <Input  id ="first_name" bg = "white" placeholder={"First Name"} size = "lg"></Input>
                        </VStack>
                        <VStack align = "start" w = {{base:"90%", md: "49%"}}>
                            <Text fontSize={"lg"}>Last Name<span  style={{ color: 'red' }}>*</span></Text>
                            <Input  id ="last_name" bg = "white" placeholder={"Last Name"} size = "lg"></Input>
                        </VStack>
                    </Stack>

                    <Stack direction={{base:"column", md: "row"}} align = "start" w={ "100%"} justifyContent={"space-between"}>
                        <VStack align = "start" w = {{base:"90%", md: "49%"}}>
                            <Text fontSize={"lg"}>Email<span  style={{ color: 'red' }}>*</span></Text>
                            <Input id = "email" bg = "white" placeholder={"Email"} size = "lg"></Input>
                        </VStack>
                        <VStack align = "start" w = {{base:"90%", md: "49%"}}>
                            <Text fontSize={"lg"}>Phone Number<span  style={{ color: 'red' }}>*</span></Text>
                            <InputGroup size = "lg" >
                                <InputLeftAddon >
                                    +61
                                </InputLeftAddon>
                                <Input id = "phone" type='tel' bg = "white" size = "lg" placeholder='Phone number'/>
                            </InputGroup>
                        </VStack>
                    </Stack>

                    <VStack align = "start" w = {{base:"100%", md: "100%"}}>
                        <Text align = "end" fontSize={"lg"}>Information<span  style={{ color: 'red' }}>*</span></Text>
                        <Textarea id = "quote" bg = "white" h = "30vh" placeholder='Please provide us more information...' />
                    </VStack>

                    <Button variant = "outline" borderColor={"black"} size = "lg" onClick = {()=>sendEmail()}>Send</Button>
                </VStack>

            </VStack>

        </>
    )
}
export default Contact;