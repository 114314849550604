import React from "react";
import {Button, Card, CardBody, CardFooter, Heading, Image, Stack, Text} from "@chakra-ui/react";


interface Contents{
    title: string;
    description: string;
    path: string;
    image: any;

}


function StepCard({title,description,path,image}:Contents){
    return (
        <>
            <Card
                direction="column"
                overflow='auto'
                variant='outline'
                width = {{base: "80vw",md:"17vw"}}
                height = {{base: "84vw", md:"22vw",xl:"34vw","2xl":"22vw"}}
                fontFamily = "Roboto"
                bg = "antiquewhite"

            >
                {image}

                <CardBody >
                    <Heading mb={"10px"} fontSize = {{base: "12px",md:"15px","2xl":"19px",}} align = "start">{title}</Heading>
                    <Text fontSize = {{base: "12px",md:"15px","2xl":"19px",}} align = "start">{description}</Text>
                </CardBody>
            </Card>

        </>
    )
}

export default StepCard;