import {
    Center,
    ChakraProvider,
    ColorModeScript,
    Flex,
    Grid,
    GridItem,
    HStack, InputLeftAddon,
    Link,
    SimpleGrid, Stack,
    useToast,
} from '@chakra-ui/react'
import React, {useRef, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardBody, CardFooter,
    Divider,
    Editable, EditableInput,
    EditablePreview,
    Heading, Input,
    InputGroup,
    Text,
    Textarea, VStack,
} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import {useInViewport} from "react-in-viewport";

function About(){
    const title_style_1 = { base: "18px", sm:"44px", md: "4vh", lg: "31px", "2xl":"35px"};
    return (
        <>
            <Box paddingTop = "100px" paddingBottom = "100px" >
                <Heading paddingBottom = "70px" fontSize={title_style_1}>MJ Carpentry and Joinery</Heading>
                <Text fontSize={"20px"}>
                    English and Australia background in carpentry and joinery have more than 19 years of working experience as
                    experts in providing highly Qualified and Licensed Carpentry and joinery Services.
                </Text>
            </Box>

        </>
    )
}

export default About;