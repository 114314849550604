import React, { useState, useEffect } from 'react';
import {
    Box,
    Button, Divider, Heading, HStack, Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Modal, ModalBody, ModalCloseButton,
    ModalContent, ModalFooter, ModalHeader,
    ModalOverlay, SimpleGrid, Text,
    useDisclosure
} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";
import service_info from "../Sections/ServiceInfo";

interface Control{
    isOpen: any;
    onOpen: any;
    onClose: any;
    title: string;
}

function ServiceCard({title, isOpen, onOpen, onClose}:Control){

    const title_style_1 = { base: "14px", sm:"45px", md: "18px", lg: "4vh", "2xl":"21px"};
    const info = title?service_info[title]:null;
    const content = title?info["content"]:null ;


    return(
        <>
            <Modal  isCentered isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <ModalContent  minW = "70vw" maxH = "70vh" >
                    <ModalHeader borderTopRadius = "5px" bg = "#AD3F45" color = "white">{title}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody fontSize = {title_style_1} overflowY={"auto"} marginRight={"5px"} marginLeft = "0px">
                        {
                            title?
                                content.map((item,index)=>
                                    <>
                                        <Heading fontSize={"20px"} as={"u"}>{item.description}</Heading>
                                        <SimpleGrid columns={4} spacing = {10} mt = "20px" mb={ "20px"} overflow = "auto" maxH = "30vh" maxW={"65vw"}>
                                            {item.img_urls.map((url,index)=>
                                                    <Image  boxSize = "15vw" src = {"/ServiceInfoImg/"+url} />
                                            )}
                                        </SimpleGrid>
                                    </>

                                ):null
                        }


                    </ModalBody>
                    <Divider></Divider>

                    <ModalFooter>
                        <Button colorScheme='gray' mr={3} onClick={onClose}>
                            Close
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )

}


export default ServiceCard;