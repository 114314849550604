import React from "react";
import {Box, Heading, HStack, Image, SimpleGrid, Stack, Text, VStack} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import {useRef} from "react";
import {useInViewport} from "react-in-viewport";
import {useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";

import step1 from "../assets/step1.jpg"
import step2 from "../assets/step2.jpg"
import step3 from "../assets/step3.jpg"
import step4 from "../assets/step4.jpg"
import step5 from "../assets/step5.jpg"

import StepCard from "../components/StepCard";


function HowToOrder(){
    const text_style = { base: "21px", sm: "30px", md: "3vw", lg: "32px", "2xl":"30px"};
    const title_style = { base: "19px", sm:"45px", md: "4vh", lg: "32px", "2xl":"40px"};
    const myref = useRef();
    const {inViewport, enterCount,
        leaveCount,} = useInViewport(
        myref,
        {disconnectOnLeave: true},
        {rootMargin: "0"}
    );

    const titleAnimation = keyframes `
        from {transform: translateY(-3vw) ; opacity :0 ;}
        to  {transform: translateY(0vw) ; opacity : 1;}
    `
    const title_animation = `${titleAnimation} 1s ease-in-out `

    const AnimatedElement = ({ delay, children }) => {
        const [isVisible, setIsVisible] = useState(enterCount>1);
        const props = useSpring({
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateX(0)' : 'translateX(-10px)',
            config: { duration: 500 },
            delay
        });

        useEffect(() => {
            setIsVisible(true);
        }, []);

        return <animated.div style={props}>{children}</animated.div>;
    };


    const paths =[step1,step2,step3,step4,step5
    ]
    const images=[];

    paths.forEach(
        (item, index )=>{
            images.push(
                <Image src = {item} objectFit='cover'
                       width = {{base: "80vw", md:"17vw"}}
                       height = {{base: "40vw", md:"10vw"}}
                       alt='picture'/>
            )
        })
    const title_style_1 = { base: "18px", sm:"44px", md: "4vh", lg: "31px", "2xl":"35px"};
    return (
        <>
            <VStack mb={"2vh"} minH = "50vh"  display = "flex" justifyContent = "center" alignItems = "center" paddingTop = "100px" paddingBottom = "100px">
                <Box ref={myref} mt="2vh">
                    <Heading paddingBottom = "70px" fontSize={title_style_1}>
                        Our Seamless process</Heading>

                    <Stack mt="2vh" opacity = {enterCount===0&&0} direction={{base:"column", md: "row"}} gap = "1vw">
                        <AnimatedElement delay={0}>
                            <StepCard image={images[0]} title={"1.REQUEST A FREE QUOTE"} description={"We will ask a few question to fill out how we can " +
                                "help, then book you in a appointment.You will receive a confirmation and a reminder close to the time."} path={step1} />
                        </AnimatedElement>
                        <AnimatedElement delay={500}>
                            <StepCard image={images[1]} title={"2.PLACE YOUR ORDER"} description={"Once we’ve finished the measurement at your project, " +
                                "we will provided a quote. Lead time to installation is typically between 2 and 3 weeks."}  path={step2}/>
                        </AnimatedElement>
                        <AnimatedElement delay={1000}>
                            <StepCard image={images[2]} title={"3.CHECK MEASURE"} description={"Your installer will visit, double check measurement, " +
                                "confirm job details, place a deposit need to get your job underway, and to order the materials to start this process, book a mutually agreeable date and time to carry out your installation."}
                                      path={step3}/>
                        </AnimatedElement>
                        <AnimatedElement delay={1500}>
                            <StepCard image={images[3]} title={"4.MANUFACTURE"}  description={"Your Product are made to measure in our Sydney factory. We will need a further deposit once the product delivered to the work site."}
                                      path={step4}/>
                        </AnimatedElement>
                        <AnimatedElement delay={2000}>
                            <StepCard image={images[4]} title={"5.INSTALLATION"}  description={"Your products are professionally fitted by our installers. We will clean up thoroughly. Final payment is due on completion."}
                                      path={step5}/>
                        </AnimatedElement>
                    </Stack>
                </Box>
            </VStack>

        </>
    )
}

export default HowToOrder;