import React, { useState, useEffect } from 'react';
import {
    HStack,
    IconButton,
    Image,
    Text,
    Badge,
    Box,
    Heading,
    Button,
    SimpleGrid,
    Grid,
    GridItem, VStack
} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import {useRef} from "react";
import {useInViewport} from "react-in-viewport";
import PictureContainer from "../components/PictureContainer";
import img9 from "../gallery/shop_front_2.jpg"
import img22 from "../gallery/hinge_door_1.jpg"
import img21 from "../gallery/window.jpg"

import img16 from "../gallery/alu_hinge_1.jpg"
import img10 from "../gallery/pivotdoor_15.jpg"
import img34 from "../gallery/cover_1.jpg"
import ImageCard from "../components/ImageCard";

import "./Gallery.css"

function Gallery (){
    const title_style_2 = { base: "19px", sm:"45px", md: "4vh", lg: "32px", "2xl":"40px"};
    const titleAnimation = keyframes `
        from {transform: translateY(-3vw) ; opacity :0 ;}
        to  {transform: translateY(0vw) ; opacity : 1;}
    `
    const title_animation = `${titleAnimation} 1s ease-in-out `

    const appear = keyframes `
        from { opacity :0 ;}
        to  {opacity : 1;}
    `
    const appear_ani_1 = `${appear} 0.5s ease-in-out`
    const appear_ani_2 = `${appear} 1s ease-in-out`
    const appear_ani_3 = `${appear} 1.5s ease-in-out`
    const appear_ani_4 = `${appear} 2s ease-in-out`
    const appear_ani_5 = `${appear} 2.5s ease-in-out`
    const appear_ani_6 = `${appear} 3s ease-in-out`



    const myref = useRef();
    const {inViewport, enterCount,
        leaveCount,} = useInViewport(
        myref,
        {disconnectOnLeave: true},
        {rootMargin: "0"}
    );

    const width = {base:"66vw",lg:"800px","2xl":"44vw"}
    const height = {base:"48vw",lg:"1100px","2xl":"32vw"}
    const title_style_1 = { base: "18px", sm:"44px", md: "4vh", lg: "31px", "2xl":"35px"};
    return(
        <>
            <VStack minH = "fit-content" display = "flex" justifyContent = "start" alignItems = "center" paddingTop = "100px" paddingBottom = "100px">
                <Box mt="2vh" align = "center" ref={myref}>
                    {enterCount > 0?
                    <>
                        <Heading paddingBottom = "70px" fontSize={title_style_1}>
                        Our work gallery</Heading>

                        {/*w:h = 11:8*/}
                    <Grid
                        h ={height}
                        w ={width}
                        templateRows='repeat(8, 1fr)'
                        templateColumns='repeat(11, 1fr)'
                        gap={"5px"}
                        mb={"2vh"}
                        mt="2vh"
                    >
                        <GridItem className = "grid_item" animation={enterCount === 1 ? appear_ani_2 : ""} rowSpan={4} colSpan={3} bg='tomato'
                        >
                           <ImageCard image={img9}></ImageCard>
                        </GridItem>
                        <GridItem className = "grid_item" animation = {enterCount===1?appear_ani_6:""} rowSpan={5} colSpan={4} bg='green'>
                            <ImageCard image= {img22}></ImageCard>
                        </GridItem>
                        <GridItem className = "grid_item" animation = {enterCount===1?appear_ani_3:""} rowSpan={3} colSpan={4} bg='papayawhip'>
                            <ImageCard image= {img21} ></ImageCard>
                        </GridItem>
                        <GridItem className = "grid_item" animation = {enterCount===1?appear_ani_4:""} rowSpan={5} colSpan={4} bg='green'>
                            <ImageCard image= {img10} ></ImageCard>
                        </GridItem>

                        <GridItem className = "grid_item" animation = {enterCount===1?appear_ani_2:""} rowSpan={4} colSpan={3} bg='tomato' >
                            <ImageCard image= {img16}  ></ImageCard>
                        </GridItem>
                        <GridItem className = "grid_item" animation = {enterCount===1?appear_ani_5:""} rowSpan={3} colSpan={4} bg='papayawhip' >
                            <ImageCard image = {img34} ></ImageCard>
                        </GridItem>
                    </Grid>
                    </>
                        :
                        ""
                    }
                </Box>
            </VStack>
        </>

    )
}

export default Gallery;