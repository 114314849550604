import {
    Center,
    ChakraProvider,
    ColorModeScript,
    Flex,
    Grid,
    GridItem,
    HStack,
    Link,
    Stack,
    useToast,
} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardBody, CardFooter,
    Divider,
    Editable, EditableInput,
    EditablePreview,
    Heading, Input,
    InputGroup,
    Text,
    Textarea, VStack,
} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import PictureContainer from "../components/PictureContainer";
import SideNavigation from "./SideNavigation";
import {ArrowDownIcon} from "@chakra-ui/icons";
import { useSpring, animated } from 'react-spring';
import CoverImages from "./CoverImages";
import cover1 from "../gallery/cover_1.jpg"
import cover4 from "../gallery/cover_4.jpg"
import cover3 from "../gallery/cover_3.jpg"
function Coverpage(){

    const AnimatedElement = ({ delay, children }) => {
        const [isVisible, setIsVisible] = useState(false);
        const props = useSpring({
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0vh)' : 'translateY(-2vh)',
            config: { duration: 500 },
            delay
        });

        useEffect(() => {
            setIsVisible(true);
        }, []);

        return <animated.div style={props}>{children}</animated.div>;
    };

    const title_style_1 = { base: "14px", sm:"20px", md: "18px", lg: "1.6vw", "2xl":"35px"};
    const title_style_2 = { base: "12px", sm: "15px", md: "16px", lg: "30px", xl: "1.6vw","2xl":"27px"};
    const alignStyle = {base:"start",md:"start"};


    const cover_text_1 ="Door and window furniture and hardware..."
    const cover_text_1_2 = "Fire door and fire door hardware installation.\n"
    const cover_text_1_3 = "Shelving and window sills……..\n"
    const cover_text_2 = "We craft masterpieces through custom designs and flawless finishes";
    const cover_text_2_2 = "Have more than 17 years of working experience in carpentry";
    const cover_text_3 = "We are";
    const first_part =[cover_text_1,cover_text_1_2,cover_text_1_3,cover_text_2,cover_text_2_2,cover_text_3];

    const time_gap = 600;

    const backgroundImageArr = [
        {img:cover4,bp: "0% 35%",title:"MJ carpentry and joinery",
            description:"Have more than 20 years of working experience as experts in providing high Qualified and Licensed Carpentry Services."},
        {img:cover1,bp: "0% 45%",title:"Door and window furniture and hardware...",
            description:"Fire door and fire door hardware installation, shelving and window sills... "},
        {img:cover3,bp: "0% 25%",title:"Let us build your vision, one nail at a time",
            description:"We craft masterpieces through custom designs and flawless finishes"},
    ] ;
    const [index, set_index] = useState(0);

    const img_height = {base:"35vh",md:"76vh"}
    const margin_top = {base:"10vh",md:"14vh"}

    const page_height = {base:"25vh",md:"65vh"}
    const page_margin_bottom = {base:"0vh",md:"0vh"}

    const cover_button_size = {base:"xs",md:"md",xl:"lg"}
    const cover_button_fontsize = {base:"10px",md:"16px",xl:"22px"}


    function changeimg(){
        if (index===backgroundImageArr.length-1){
            set_index(0)
        }else{
            set_index(index+1)
        }
    }

    useEffect(() => {

        const interval = setInterval(() => {
            set_index(prevValue=>(prevValue + 1) % backgroundImageArr.length)
        }, 5000);

        return () => clearInterval(interval);
    }, []);


     // setInterval(() => {
     //        changeimg()
     //        console.log(index)
     //    }, 5000);

    // clearInterval(interval)
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         changeimg()
    //         console.log(index)
    //     }, 1000);
    //     return () => clearInterval(interval);
    //
    // }, []);


    return (

    <>

            <Box marginTop ={{base:"20%",md:"10%",xl:"15%","2xl":"10%"}} h = {img_height} w = {{base: "100vw" , md:"98vw"}}  transition={"1s"}
                 align = "start"
                 style = {{

                     "background-position": backgroundImageArr[index].bp,
                     "background-size": "cover",
                     "background-repeat": "no-repeat",

                     }} display={"flex"} justifyContent={{base: "start" , md:"start"} } alignItems={{base: "center" , md:"center"}} background={`linear-gradient(rgba(50,50,50,0.5), rgba(50,50,50,0.5)),url(${backgroundImageArr[index].img})`}>

                <VStack  w ={{base:"80vw",xl:"23vw"}} h ={{base:img_height,xl:"30vh"}} ml = "2vw" align={{base: "start" , md:"start"}}  opacity={0.9} padding = "20px" >

                    <Text color = "white" as = "b" fontSize = {title_style_1}>{backgroundImageArr[index].title}<br/></Text>
                    <Text color = "white" fontSize = {title_style_2}>{backgroundImageArr[index].description}</Text>

                    <HStack mt={"10px"}>
                        <Button color = "white"  size = {cover_button_size} bg = "none" border = "1px" _hover = {{background:"white"}}  transition="1s">
                            <Text fontFamily="Calibri" fontSize={cover_button_fontsize} onClick = {()=>{
                                document.getElementById("contact").scrollIntoView({ behavior: 'smooth',block: 'end'});
                            }}>Book a free Measure & Quote</Text>
                        </Button>
                        <Button color = "white"  size = {cover_button_size}   bg = "none" border = "1px" _hover = {{background:"white"}}  transition="1s">
                            <Text fontFamily="Calibri" fontSize={cover_button_fontsize} onClick = {()=>{
                            document.getElementById("gallery").scrollIntoView({ behavior: 'smooth',block: 'center'});
                            }} >Visit work gallery</Text>
                        </Button>
                    </HStack>
                </VStack>

            </Box>

    </>
    )
}

export default Coverpage;