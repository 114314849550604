import React from "react";
import {Box, Heading, HStack, Image, SimpleGrid, Stack, Text, VStack} from "@chakra-ui/react";
import {keyframes} from "@emotion/react";
import {useRef} from "react";
import {useInViewport} from "react-in-viewport";
import {useEffect, useState} from "react";
import {animated, useSpring} from "react-spring";
import test0 from "../gallery/5.jpg";
import test1 from "../gallery/11.jpg";
import test2 from "../gallery/13.jpg";
import test3 from "../gallery/10.jpg";
import {isVisible} from "@testing-library/user-event/dist/utils";

function Services(){

    const text_style = { base: "21px", sm: "30px", md: "3vw", lg: "32px", "2xl":"30px"};

    const myref = useRef();
    const {inViewport, enterCount,
        leaveCount,} = useInViewport(
        myref,
        {disconnectOnLeave: true},
        {rootMargin: "0"}
    );

    const titleAnimation = keyframes `
        from {transform: translateY(-3vw) ; opacity :0 ;}
        to  {transform: translateY(0vw) ; opacity : 1;}
    `
    const title_animation = `${titleAnimation} 1s ease-in-out `

    const servicesArray = [
        "Second fix, finishing carpentry services, Skirtings, Architraves (mouldings).",
        "Internal and external Doors and Door Jambs.",
        "Door and window furniture and hardware.",
        "Pivot door systems.",
        "Sliding Timber and Aluminium Doors installation.",
        "Bi-fold Timber and Aluminium Doors installation.",
        "Timber and Aluminium Windows, Bi-fold, Awning, Sliding installation.",
        "Double glazing window and door systems.",
        "Window and door gasket sealing systems.",
        "Shelving and window sills.",
        "Cabinetry installations.",
        "Wardrobe installations.",
        "Timber Stair case construction and installation.",
        "Customised bespoke Joinery.",
        "Timber Balustrading and Hand-rails.",
        "Decking installation.",
        "Pergola erection.",
        "Fire door and fire door hardware installation.",
        "Timber wall panelling and wainscotting, picture framing.",
        "Partition walling, Wall cladding.",
        "Frameless and framed mirror installation.",
        "Floating laminate floor laying.",
        "Architectural feature carpentry and joinery."
    ].sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));


    const title_style_1 = { base: "18px", sm:"44px", md: "4vh", lg: "31px", "2xl":"35px"};
    const title_style_2 = { base: "14px", sm: "15px", md: "3vw", lg: "20px", xl: "2vh","2xl":"20px"};

    const AnimatedElement = ({ delay, children }) => {
        const [isVisible, setIsVisible] = useState(enterCount > 1);
        const props = useSpring({
            opacity: isVisible ? 1 : 0,
            transform: isVisible ? 'translateY(0)' : 'translateY(-10px)',
            config: { duration: 300},
            delay,
        });

        useEffect(() => {

            setIsVisible(true);
        }, []);



        return <animated.div style={props}>{children}</animated.div>;
    };



    return (
        <>

            <VStack   minH = "50vh" w = {{base: "100vw" , md:"80vw"}} fontFamily = "Roboto" display = "flex" justifyContent = "center" alignItems = "center"  bg = "white" paddingTop = "100px" paddingBottom = "100px">
                <Box  ref={myref} mt = "2vh">
                    <Heading paddingBottom = "70px" fontSize={title_style_1}>
                        Qualified and Licensed Carpentry Services</Heading>

                    <Stack  mt="2vh" direction={{base:"column", md: "row"}} align = "start" gap={{base:"0", md: 20}} display = "flex"
                           justifyContent = "center" alignItems = {{base:"start", md: "start"}} w ={{base:"70vw", md: "100%"}}>

                        <VStack align={"start"}>
                            {
                                servicesArray.slice(0,Math.ceil(servicesArray.length/2)).map((item, index) =>
                                    <AnimatedElement delay={index*100}>
                                        <Text align = "start" fontSize= {title_style_2}>&#8226; {item}</Text>
                                    </AnimatedElement>

                                )
                            }
                        </VStack>

                        <VStack  align={"start"}>
                            {
                                servicesArray.slice(Math.ceil(servicesArray.length/2),servicesArray.length).map((item, index) =>
                                    <AnimatedElement delay={index*100}>
                                        <Text  align = "start" fontSize= {title_style_2}>&#8226; {item}</Text>
                                    </AnimatedElement>

                                )
                            }
                        </VStack>

                    </Stack>
                </Box>
            </VStack>
        </>
    )
}

export default Services;
