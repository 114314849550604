import React, { useState, useEffect } from 'react';
import {Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";


interface Content{
    text: string;
    clickfunc: any;
    items: any;
}

function NavBarMenu({text, clickfunc, items}: Content){
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Menu isOpen={isOpen} offset={[0,0]}>
                <MenuButton _hover = {{background:"#AD3F45",color:"white"}}  borderRadius={0}  h={"5vh"} bg={"white"}  onMouseEnter={onOpen} fontSize = {window.innerWidth > 768?"0.8vw":"14px"}
                            onMouseLeave={onClose}  as={Button} rightIcon={<ChevronDownIcon />}>
                    {text}
                </MenuButton>
                <MenuList borderRadius={0} onMouseLeave={onClose} onMouseEnter={onOpen}>
                    { items&&
                        items.map((item,index)=>
                            <MenuItem  _hover = {{background:"#AD3F45",color:"white"}} onClick={()=>clickfunc(item)}>{item}</MenuItem>)
                    }
                </MenuList>
            </Menu>
        </>
    )

}

export default NavBarMenu;